import { SvgIconProps } from "../types";

export const SvgIcon = ({
  extension = "png",
  src,
  width,
  height,
  link = "",
}: SvgIconProps) => {
  const handleNavigate = () => {
    if (link) {
      window.open(link, "_blank");
    }
  };
  return (
    <img
      src={`/img/${extension}/${src}`}
      alt={src}
      style={{ width, height, borderRadius: "10px", cursor: "pointer" }}
      width={width}
      height={height}
      loading="lazy"
      onClick={handleNavigate}
    />
  );
};
